$color-collection-alt: (
    #fe9696,
    #ff9883,
    #ff9e6d,
    #ffa854,
    #ffb537,
    #f5c40c,
    #dcd400,
    #b9e300,
    #87f200,
    #00ff36
);

$color-collection-reverse: (
    #00ff36,
    // Bright Green
    #66ff00,
    // Lime Green
    #ccff00,
    // Yellow Green
    #ffcc00,
    // Yellow
    #ff6600,
    // Orange
    #ff0000 // Red
);

.circle {
    width: 28px;
    height: 28px;
    border-radius: var(--borderRadius);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mainFg);
    font-size: 90%;
    font-weight: bold;
    border: 1px solid;
    cursor: pointer;

    span {
        padding-bottom: 1px;
    }

    @for $i from 0 to length($color-collection-alt) {
        &.rating-#{$i} {
            background-color: nth($color-collection-alt, $i+1 );
            border-color: darken(nth($color-collection-alt, $i+1),
                    10%);
            color: darken(nth($color-collection-alt, $i+1),
                    30%);
        }
    }

    @for $i from 0 to length($color-collection-reverse) {
        &.rating-reverse-#{$i} {
            background-color: nth($color-collection-reverse, $i+1 );
            border-color: darken(nth($color-collection-reverse, $i+1),
                    10%);
            color: darken(nth($color-collection-reverse, $i+1),
                    40%);
        }
    }
}